@import "src/assets/styles/switch.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: "Nunito", system-ui, sans-serif;
    }

    [type='text']:focus,
    [type='email']:focus,
    [type='url']:focus,
    [type='password']:focus,
    [type='number']:focus,
    [type='date']:focus,
    [type='datetime-local']:focus,
    [type='month']:focus,
    [type='search']:focus,
    [type='tel']:focus,
    [type='time']:focus,
    [type='week']:focus,
    [multiple]:focus,
    textarea:focus,
    select:focus {
        --tw-ring-color: #FFC869;
        border-color: var(--tw-ring-color);
    }

    [type="checkbox"],
    [type="radio"] {
        color: #FFC869;
    }

    [type="checkbox"]:focus,
    [type="radio"]:focus {
        --tw-ring-color: #FFC869;
    }

    button:focus,
    a:focus {
        border-color: #FFC869;
        outline: none;
    }
}

::-webkit-input-placeholder {
    font-style: italic;
}

:-moz-placeholder {
    font-style: italic;
}

::-moz-placeholder {
    font-style: italic;
}

:-ms-input-placeholder {
    font-style: italic;
}

.loading-section {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    align-items: center;
    justify-content: center;
    z-index: 10000;
}

.foreground {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 100vw;
    background-color: #334F65;
    opacity: 0.8;
    pointer-events: none;
    z-index: 1000;
}

.uneditable {
    pointer-events: none;
}

.even-column-grid {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    gap: 3em;
    align-items: end;
}

.appointment-filters {
    display: grid;
    grid-auto-flow: column;
    gap: 3em;
    place-items: center;
    place-content: center;
}

.intake-section {
    border: #F5F7F8 solid;
    /*box-shadow: 10px 3px 10px rgb(0 0 0 / 0.2);*/
}

.meeting-link {
    background-color: #E7F4FE;
    border: #0C80E3 solid;
    border-radius: 5px;
}

.sub-header {
    padding-left: 5px;
}

.intake-input {
    background-color: #F2F3F5;
    border: #E0E0E0 solid;
    border-radius: 5px;
}

.trauma-history {
    background-color: #FFEFD5;
    border: #FFD89A solid;
    border-radius: 5px;
}

.autocomplete {
    z-index: 100;
}

.floating-section {
    position: sticky;
    top: 0;
    z-index: 9999;
    background: #F7F8FA;
}

p.error-msg {
    color: #B00020
        /* #bf1650; */
}

p.error-msg::before {
    display: inline;
    content: "⚠ ";
}

p.success-msg {
    color: #28911F
        /* #bf1650; */
}

p.success-msg::after {
    display: inline;
    content: " ✔";
    font-size: medium;
}

fieldset:disabled input.text-sjOrange {
    color: #999;
}

fieldset:disabled input.text-blue-500 {
    color: #999;
}

fieldset:disabled input[type=checkbox] {
    color: #999;
}

fieldset:disabled textarea,
textarea[disabled] {
    background-color: #CCD3D8;
}

fieldset:disabled input[type=text] {
    background-color: #CCD3D8;
}

fieldset:disabled button.bg-sjOrange {
    background-color: #999;
}

.ReactModal__Html--open,
.ReactModal__Body--open {
    overflow: hidden;
}

.summary-table th {
    font-size: 16px;
    font-weight: 600;
    line-height: 24.55px;
    text-align: left;
    padding: 1rem 0.5rem;
    width: 25%;
}

.summary-table td {
    font-size: 14px;
    font-weight: 400;
    line-height: 21.82px;
    letter-spacing: 0.025em;
    text-align: left;
    padding: 1rem 0.5rem;
    width: 25%;
}

/* .ReactModal__Overlay {
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ReactModal__Content {
    background: white;
    width: 50rem;
    max-width: calc(100vw - 2rem);
    max-height: calc(100vh - 2rem);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25);
    overflow-y: auto;
    position: relative;
  }  */

.PhoneInputInput {
    /*border-radius: 6%;*/
    border-color: #FFA200;
    color: gray;
    outline: none;
}

#phoneLocation,
#phoneUser {
    border-radius: 6px;
    border-color: #E0E0E0 !important;
    color: black;
    font-size: 0.875rem
}

.react-tooltip {
    background: #000000 !important;
}

.screening-summary-wrapper {
    display: flex;
    column-gap: 10px;
    align-items: center;

    .screening-date {}

    .screening-info-icon {
        cursor: pointer;
    }
}

.screening-summary-tooltip-wrapper {
    font-size: 12px;
    padding: 12px 5px;

    .screening-summary-tooltip-header {
        margin-bottom: 16px;
        font-size: 14px;
    }

    .screening-summary-tooltip-content {

        .screening-summary-screeners {
            counter-reset: item;

            .screening-summary-screener-item {
                display: flex;
                align-items: center;
                line-height: 16px;
                margin-bottom: 5px;
                counter-increment: item;

                &:before {
                    content: counter(item) ". ";
                }

                .screening-summary-screener-item-label {
                    margin-left: 5px;
                }

                .screening-summary-screener-item-interpretation {}
            }
        }
    }
}

.screener-interpretation-label {
    &.text {
        background: none;
        font-style: italic;
    }

    &.screener-interpretation-negative {
        &.text {
            color: #4DB469;
        }
    }

    &.screener-interpretation-positive {
        &.text {
            color: #F61717;
        }
    }
}

.patient-details-screen {

    .patient-basic-details-card {
        border-radius: 5px;
        background: #FFF;
        padding: 16px 14px;

        .patient-basic-details-meta {

            .patient-name {
                color: #1D1B20;
                font-size: 16px;
                font-weight: 600;
                letter-spacing: 0.024px;
                margin-bottom: 17px;
            }

            .patient-practice {
                color: #5D7285;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.035px;
            }

            .patient-phone-number {
                color: #5D7285;
                font-size: 14px;
                font-weight: 400;
            }
        }

        .patient-basic-details-actions {}
    }

    .patient-sub-menu-wrapper {
        // margin-top: 24px;
        display: flex;
        column-gap: 24px;

        .patient-sub-menu-navigation {
            border-radius: 5px;
            background: #FFF;
            padding: 32px 16px;
            width: 174px;

            .patient-sub-menu-list {
                display: flex;
                flex-direction: column;
                row-gap: 32px;

                .patient-sub-menu-item {
                    .patient-sub-menu-item-title {
                        color: #5D7285;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 20px;
                    }

                    &.active {
                        .patient-sub-menu-item-title {
                            color: #FFA200;
                        }
                    }
                }
            }
        }

        .patient-sub-menu-route-outlet {
            border-radius: 5px;
            background: #FFF;
            padding: 23px 16px;
            flex: 1;
        }
    }
}

.data-label-component {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    .data-label-title {
        color: #5D7285;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        /* 128.571% */
    }

    .data-label-content {
        color: #1D1B20;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.035px;
    }
}

.long-text {
    text-overflow: ellipsis;
    margin-bottom: 1px;
    cursor: pointer;
    word-break: break-all;
    overflow: hidden;
}

.ellipsis-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.patient-information-wrapper {
    .patient-information-card {

        .patient-information-section {
            padding: 24px 0;
            border-bottom: 1px solid #ECECEC;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
                border-bottom: none;
            }

            .patient-information-section-title {
                color: #242731;
                font-size: 16px;
                font-weight: 600;
                /*line-height: 36px; !* 225% *!*/
                margin-bottom: 24px;
            }

            .patient-information-section-content {}
        }
    }
}

.table {

    &.table-small {
        th {
            font-size: 12px;
            font-weight: 600;
        }

        td {
            font-size: 12px;
            font-weight: 400;
        }
    }

    &.table-no-horizontal-lines {}
}

.custom-sidebar {
    width: 45%;
}

.custom-sidebar>div::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 45%;
    background-color: #334F65;
    opacity: 0.8;
    z-index: 1000;
}

.w-auto {
    width: auto !important;
}

.ReactModal__Overlay {
    z-index: 9999 !important;
}

.react-select__menu-portal {
    z-index: 9999 !important;
}

.align-items-center {
    align-items: center;
}

.align-items-flex-start {
    align-items: flex-start;
}

.align-self-flex-start {
    align-self: flex-start;
}

.card-loading {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #e2e5e7;
    border-radius: 5px;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
    background-size: 40px 100%;
    background-repeat: no-repeat;
    background-position: -40px 0;
    animation: shine 800ms ease infinite;
}

#jsd-widget {
    // bottom: 120px !important;
    left: 25px !important;
    width: 176px;
}

.confiramtion-modal-info {
    background-color: #DFEDFE;
    border: 1px solid #0C80E3;
    border-radius: 5px;
    color: #0C80E3;
    font-size: 14px;
}

.soap-physician-wrapper {
    font-weight: 500;
    font-family: Nunito;

    .patient-name {
        color: #1D1B20;
        font-size: 16px;
    }

    .patient-dob {
        color: #5D7285;
        font-size: 12px;
    }

    .patient-location {
        color: #5D7285;
        font-size: 12px;
        font-style: italic;
    }

    .react-datepicker-popper {
        z-index: 9999;
    }

    .soap-notes-list-view {
        .soap-notes-list-view-header-section {
            .soap-notes-list-view-header {
                color: #1D1B20;
                font-size: 16px;
            }

            .soap-notes-list-view-description {
                color: #5D7285;
                font-size: 12px;
            }
        }
    }
}

.custom-italic-select {
    font-style: italic;
}

.react-multiple-carousel__arrow {
    min-width: 20px !important;
    min-height: 20px !important;
    bottom: 28px !important;
}

.react-multiple-carousel__arrow--left {
    left: calc(4% + -30px) !important;
}

.react-multiple-carousel__arrow--right {
    right: calc(4% + -30px) !important;
}

.react-multiple-carousel__arrow::before {
    font-size: 12px !important;
}

.react-datepicker__close-icon {
    top: 2px !important;
    right: 5px !important;

    &:after {
        background-color: #939393 !important;
    }
}

.sj-react-select__control {
    min-height: 40px !important;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.spin {
    animation: spin 2s linear infinite;
}

@keyframes infinite-spinning {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.infinite-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top-color: #333;
    border-radius: 50%;
    animation: infinite-spinning 1s infinite linear;
}

.rbc-time-slot {
    min-height: 30px !important;
}

.rbc-time-header {

    .rbc-row {
        min-height: 40px !important;
    }

    .rbc-allday-cell {
        display: none;
    }
}

.rbc-events-container {
    margin: unset !important;
}
